<template>
    <modal class="selectOldDateModel" :isShow="isShow">
        <div class="header">选以往日期加载沽清</div>
        <div class="modal-body">
            <div class="tr">
                <span class="lable-txt">营业日期：</span>
                <el-date-picker class="from-date"
                v-model="businessHours"
                type="date"
                placeholder=""
                :default-value="new Date()">
                </el-date-picker>
            </div>

            <div class="table-box grey-table" v-table-el-height="'tableEl'">
                <el-table ref="tableEl" border :data="tableData"  style="width: 100%;"  
                @selection-change="handleSelectionChange"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column type="selection" label="选择" width="60" ></el-table-column>
                    <el-table-column prop="Eat_XFKINDNAME" label="菜品类别" min-width="86" align="left"></el-table-column> 
                    <el-table-column prop="Eat_XFNAME" label="菜品名称" min-width="124" align="left"></el-table-column>
                    <el-table-column prop="Eat_Size" label="菜品单位" min-width="124" align="left"></el-table-column>
                    <el-table-column prop="Limited_Num" label="估清/限售数量" width="120"> 
                        <template #default="scope">
                            <span v-if="scope.row.SoldOut_Type==2">{{scope.row.Limited_Num}}</span>
                            <span v-if="scope.row.SoldOut_Type==0">沽清</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="EAT_PHASENAME" label="限售时段" min-width="104">
                        <template #default="scope">
                            <span v-if="scope.row.SoldOut_Type==2">{{scope.row.EAT_PHASENAME}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">引用</button>
        </div>
    </modal>
</template>

<script>
// 选以往日期加载沽清
export default {
    name:'selectOldDateModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**数据 记载中 */
            tableLoading:true,
            /**营业日期 */
            businessHours:new Date(),
            /**沽清数据 */
            tableData:[],
            /**选中的数据 */
            multipleSelection: []
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        businessHours(){
            this.loadData();
        }
    },
    methods:{
        /**初始化数据 */
        init(){
            let userInfo= this.$auth.getUserInfo();
            if(userInfo){//营业日期
                let rptDate=new Date(userInfo.Rpt_Date);
                this.businessHours=new Date(rptDate.setDate(rptDate.getDate()-1));
            }
            this.loadData();
        },
        /**加载沽清数据 */
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.tableData=[];
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetSoldOutInfo",{
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                Operator_Name:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableData=d.ResponseBody.doSoldOutList;
                }else{
                    this.tableData=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableData=[];
                loading.close();
                console.log('加载沽清数据失败：'+e);
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        /**纪录选中的数据 */
        handleSelectionChange(val){
            this.multipleSelection=val;
        },
        confirm(){
            let selectSoldOutData=[];
            this.multipleSelection.forEach((item)=>{
                selectSoldOutData.push(Object.assign({},item,{SoldOut_ID:''}));
            })
            if(selectSoldOutData.length==0){
                this.$message.warning('未勾选任何数据，请先勾选数据！');
                return;
            }
            this.$emit("confirm",selectSoldOutData);
        }
    }
}
</script>

<style lang="scss">
@import './selectOldDateModel.scss'
</style>